import axios from 'axios'
import Analytics from 'components/Analytics'
import ProductDisplay from 'components/ProductDisplay'
import SEO from 'components/SEO'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import { renderContentBlocks } from '../lib/renderContentBlocks'

const ProductTemplate = ({ data, pageContext: { pageUrl }, serverData }) => {
  const { enableDebug, variants, ...productVals } = data.product
  const { reviews } = serverData

  const availableVariants = variants.filter(v => enableDebug || v.isPublished)

  return (
    <div>
      <SEO
        defaultMeta={{ metaTitle: data.product.name }}
        pageMeta={data.product.meta}
        pagePath={pageUrl}
      />
      <Analytics {...productVals.analytics} />
      {availableVariants && availableVariants.length && (
        <ProductDisplay variants={availableVariants} reviewMeta={reviews} {...productVals} />
      )}
      <div>
        {renderContentBlocks(
          data.product.contentBlocks,
          data.product.shopifyProductId,
          data.product.name
        )}
      </div>
    </div>
  )
}

export default ProductTemplate

ProductTemplate.propTypes = {
  data: PropTypes.shape({
    product: PropTypes.shape({
      meta: PropTypes.object,
      analytics: PropTypes.object,
      name: PropTypes.string,
      contentBlocks: PropTypes.arrayOf(
        PropTypes.shape({
          _key: PropTypes.string.isRequired,
          _type: PropTypes.string.isRequired,
        }).isRequired
      ),
      variants: PropTypes.arrayOf(PropTypes.shape({ isPublished: PropTypes.bool.isRequired })),
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    pageUrl: PropTypes.string.isRequired,
  }).isRequired,
  serverData: PropTypes.shape({
    reviews: PropTypes.shape({
      total_review: PropTypes.number,
      average_score: PropTypes.number,
      total_organic_reviews: PropTypes.number,
      organic_average_score: PropTypes.number,
    }),
  }),
}

export const query = graphql`
  query ProductQuery($id: String!) {
    product: sanityProduct(id: { eq: $id }) {
      meta {
        ...MetaCard
      }
      analytics {
        ...AnalyticsCard
      }
      ...Product
      contentBlocks {
        ...Blocks
      }
    }
  }
`

export async function getServerData(context) {
  try {
    const { shopifyProductId, shopifyBulkProductId } = context.pageContext
    const yotpoKey = process.env.YOTPO_API_KEY

    let reviews

    if (shopifyProductId && yotpoKey) {
      const config = {
        method: 'get',
        url: `https://api.yotpo.com/v1/widget/${yotpoKey}/products/${shopifyProductId}/bottomline`,
      }
      const response = await axios(config)

      if (response.status === 200) reviews = response.data.response.bottomline
      else throw new Error(`Response failed`)
    }

    return {
      props: {
        reviews,
      },
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    }
  }
}
